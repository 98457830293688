<template>
  <b-table
    :data="history"
    :striped="true"
    :loading="isLoading"
    :default-sort="['timestamp', 'asc']">
      <b-table-column field="paymentName" label="Payment Name" sortable v-slot="props">
        <b-tooltip :label="props.row.paymentId" dashed>
          {{ props.row.paymentName }}
        </b-tooltip>
      </b-table-column>
      <b-table-column field="paymentId" label="Payment Id" sortable v-slot="props">
        <b-tooltip :label="props.row.paymentId" dashed>
          {{ props.row.paymentId }}
        </b-tooltip>
      </b-table-column>
      <b-table-column field="level" label="Level" centered sortable v-slot="props">
        <span class="tag" :class="props.row.levelClass">
          {{ props.row.level }}
        </span>
      </b-table-column>
      <b-table-column field="status" label="Code" sortable v-slot="props">
        {{ props.row.status }}
      </b-table-column>
      <b-table-column field="timestamp" label="Date" sortable v-slot="props">
        <b-tooltip :label="props.row.timestamp" dashed>
          {{ props.row.timestamp | moment("LLL") }}
        </b-tooltip>
      </b-table-column>
      <b-table-column field="msg" label="Message" v-slot="props">
        {{ props.row.msg }}
      </b-table-column>
    <template slot="empty">
      <section class="section" v-if="!isLoading">
          <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"></b-icon>
              </p>
              <p>{{ historyErr }}</p>
          </div>
      </section>
    </template>
  </b-table>
</template>
<script>
import { get } from 'lodash';

export default {
  props: {
    tenant: String,
    paymentPlan: Object,
    cleanPaymentData: Object,
    historyErr: String,
  },
  mounted() {
    const dataService = this.$dataService(this.tenant);
    const levelClasses = {
      ERROR: 'is-danger',
      WARNING: 'is-warning',
      INFO: 'is-info',
    };

    dataService
      .paymentPlans
      .history(this.cleanPaymentData.paymentPlanId)
      .then((result) => {
        this.history = result.data.map((o) => {
          const payment = this.paymentPlan && this.paymentPlan.payments[o.paymentId];

          return {
            paymentId: o.paymentId,
            paymentName: get(payment, 'name', 'Unknown'),
            level: o.level,
            levelClass: levelClasses[o.level] || 'is-danger',
            status: o.status,
            timestamp: o.timestamp,
            msg: o.userMessage,
          };
        });
      }).catch((err) => {
        if (err.response.status === 404) {
          this.historyErr = 'No histories found. This can either be due to an internal error or more likely, we haven\'t got a response from the payment provider yet.';
        } else {
          this.historyErr = 'Error loading history.';
        }
      })
      .finally(() => { this.isLoading = false; });
  },
  data() {
    return {
      isLoading: true,
      history: [],
    };
  },
};
</script>
